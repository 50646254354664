import React, { Fragment } from "react";
// import Confetti from "react-confetti";

import stjude from "../../../img/sj_logo2.png";
// import { doctorIcon } from "../../../img/icons";

const Fundraiser = (props) => {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  return (
    <Fragment>
      <img src={stjude} alt="St. Jude" className="sj_logo" />
      {/* <div className="fundraiser-view">
        <Confetti
          width={1200}
          height={961}
          numberOfPieces={50}
          gravity={0.1}
          // gravity={0.04}
          colors={[
            "#8332a7",
            "#1874dc",
            "#64d0e4",
            "#c0da07",
            "#ffc32c",
            "#490e67",
            "#002856",
            "#1eadc1",
            "#75bc21",
            "#ffb125",
            "#c10f3a",
          ]}
        />
        <div className="gtg-fundraiser">
          <div className="username">
            <span>warchant</span>
          </div>
          <div className="amount_raised">
            <span>$10,000</span>
            <p>Lifetime Raised For St. Jude Children's Research Hospital</p>
          </div>
          <div className="gtg-value">
            <div className="icon_wrapper">
              <img src={doctorIcon} alt="icon" />
            </div>
            <span>Could help provide 221 toddler diapers</span>
          </div>
        </div>
      </div> */}
      <div className="fundraiser-view">
        {/* <Confetti
          width={1200}
          height={961}
          numberOfPieces={50}
          gravity={0.1}
          // gravity={0.04}
          colors={[
            "#8332a7",
            "#1874dc",
            "#64d0e4",
            "#c0da07",
            "#ffc32c",
            "#490e67",
            "#002856",
            "#1eadc1",
            "#75bc21",
            "#ffb125",
            "#c10f3a",
          ]}
        /> */}
        <div className="gtg-fundraiser">
          <div className="username">
            <span>{props.username}</span>
          </div>
          <div className="amount_raised">
            <span>
              {props.amount_raised - Math.floor(props.amount_raised) !== 0
                ? formatter.format(props.amount_raised)
                : "$" + formatNumber(props.amount_raised)}
            </span>
            <p>Lifetime Raised For St. Jude Children's Research Hospital</p>
          </div>
          <div className="gtg-value">
            <div className="icon_wrapper">
              <img src={props.icon} alt="icon" />
              {/* <img src={doctorIcon} alt="icon" /> */}
            </div>

            <span>
              Could help {props.gtgProvideCover} {formatNumber(props.gtgAmount)}{" "}
              {props.gtg}
            </span>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Fundraiser;
