import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { Hint } from "react-autocomplete-hint";

import PublicFundraiser from "../../Public/PublicFundraiser/PublicFundraiser";

// import BackgroundVideo from "../../img/background.mp4"
import stjude from "../../../../img/sj_logo.png";
import tiltify from "../../../../img/tiltify_logo.png";
import { useGetEveryone, useGetUser } from "../../../../data";


// import elizabeth from "../../../../img/elizabeth.jpg";
// import jacob from "../../../../img/jacob.jpg";

//
// get all the FR ind and team
//

//
const People = (props) => {
  const [userSearch, setUserSearch] = useState("");
  const [fundraiser, setFundraiser] = useState(false);
  const [checked, setChecked] = useState(true);
  const [usernameOrTeam, setUserNameOrTeam] = useState("Tiltify username");
  //const [userInfo, setUserInfo] = useState([]);
  const [validation, setValidation] = useState(false);
  const [getUserEnabled, setEnabled] = useState(false);
  //const [peopleList, setPeopleList] = useState([]);
  const {data:peopleList }=useGetEveryone();
  const {data:userInfo,refetch }=useGetUser(encodeURIComponent(userSearch.toLowerCase()), !checked, getUserEnabled);

  const handleTeamChange = () => {
    setChecked(false);
    setUserNameOrTeam("Tiltify team name");
  };
  const handleIndChange = () => {
    setChecked(true);
    setUserNameOrTeam("Tiltify username");
  };

  const Checkbox = ({
    id,
    type,
    name,
    value,
    defaultChecked,
    className,
    onChange,
  }) => {
    return (
      <div>
        {/* <input name={name} type="radio" value={value} />
        <label htmlFor={value}>{label}</label> */}

        <input
          type={type}
          id={id}
          name={name}
          value={value}
          defaultChecked={defaultChecked}
          // onChange={onChange}
        />
        <label className={className} onClick={onChange} htmlFor={id}>
          {value}
        </label>
      </div>
    );
  };
 
  const handleSubmit = (event) => {
    event.preventDefault();
    refetch();
    setEnabled(true);
    /* axios
      .get(process.env.REACT_APP_SERVICE + `/user/${encodeURIComponent(userSearch.toLowerCase())}/${!checked}`)
      .then((response) => {
        // console.log(
        //   "getUser response?",
        //   response,
        //   "usersearch: ",
        //   userSearch,
        //   "team?: ",
        //   checked
        // );
       // console.log(response.data);
        setUserInfo(response.data.rows);

        if (response.data.data.length === 0) {
          setValidation(true);
          setTimeout(() => {
            setValidation(false);
          }, 5000);
        }
        if (response.data.data.length > 0) {
          setValidation(false);
          // setTimeout(() => {
          //   setFundraiser(false);
          //   setUserSearch("");
          // }, 25000);
        }
      })
      .catch((err) => {
        console.log("ERROR", err);
      }); */
  };

  useEffect(() => {
    if (userInfo&&userInfo!=="NODATA") {
      console.log("UserInfo",userInfo);
      setFundraiser(true);
    }
  }, [userInfo]);
  return (
    <Fragment>
      {(fundraiser&&userInfo) ? (
        
          <PublicFundraiser
            username={userInfo.rows.name}
            amount_raised={
              Number(userInfo.rows.amount_raised) + Number(userInfo.rows.offline_amount_raised)
            }
            team={userInfo.rows.team}
            key={Math.random()}
          />
        
      ) : (
        <div className="public">
          <div id="usernames" className="usernames">
            <div className="usernames__thank_you">
              <img src={stjude} alt="St. Jude Children's Research Hospital" />
              <p>Celebrate your lifetime fundraising&nbsp;total!</p>
            </div>

            <div className="usernames__search_button">
              <div className="usernames__select">
                <Checkbox
                  type="radio"
                  id="individual"
                  name="indorteam"
                  value="Individual"
                  defaultChecked="defaultChecked"
                  className={checked && "active"}
                  onChange={handleIndChange}
                />
                <Checkbox
                  type="radio"
                  id="team"
                  name="indorteam"
                  value="Team"
                  className={!checked && "active"}
                  onChange={handleTeamChange}
                />
              </div>

              <form onSubmit={handleSubmit}>
                <Hint allowTabFill={true} options={peopleList}>
                  <input
                    type="text"
                    // placeholder="Tiltify username"
                    placeholder={usernameOrTeam}
                    autoComplete="off"
                    className="search_field"
                    name="name"
                    autoFocus
                    spellCheck="false"
                    onChange={(event) => setUserSearch(event.target.value)}
                  />
                </Hint>
                {validation ? (
                  <p className="validation">
                    Please enter a valid Tiltify username
                  </p>
                ) : (
                  <p></p>
                )}
                <button type="submit">Search</button>
              </form>

              <img src={tiltify} alt="Tiltify" className="tiltify" />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default People;
