import React from "react";

import Main from "./Kiosk/Main";
import Commitment from "./Kiosk/Commitment";

const GTGBase = (props) => {
  return (
    <div className="base">
      {props.type === "gtg" ? (
        <Main />
      ) : props.type === "commitment" ? (
        <Commitment />
      ) : (
        ""
      )}
      {/* <Main /> */}
    </div>
  );
};

export default GTGBase;
