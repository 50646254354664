import React, { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import KioskBase from "./components/TotalRaised/KioskBase";
import PublicBase from "./components/TotalRaised/PublicBase";
import Closest from "./components/Closest";
import GTGBase from "./components/GTG/GTGBase";

function App() {
  return (
    <Fragment>
      <Router>
        <Routes>
          <Route path="/total-raised-kiosk" element={<KioskBase />} />
          <Route path="/total-raised/" element={<PublicBase />} />
          <Route path="/gtg-kiosk/*" element={<GTGBase type={"gtg"} />} />
          <Route
            path="/gtg-kiosk-commitment/*"
            element={<GTGBase type={"commitment"} />}
          />
          <Route path="/closest/" element={<Closest />} />
        </Routes>
      </Router>
    </Fragment>
  );
}

export default App;
