import React, { useState, Fragment, useEffect } from "react";
import axios from "axios";
import Confetti from "react-confetti";

import convertGTG from "../../hooks/useGTG";
import stjude from "../../../img/sj_logo.png";
import { useGTGMilestones } from "../../../data";

const Commitment = (props) => {
  //const [gtgList, setGTGList] = useState([]);
  const [selectedCampaignGoal, setSelectedCampaignGoal] = useState("");
  const [list, setList] = useState(false);
  const [gtgAmount,setGTGAmount] = useState(null);
  const handleOnChange = (e) => {
    setSelectedCampaignGoal(e.target.value);
  };

  const {data:gtgList,isPending,error }=useGTGMilestones(gtgAmount);

  useEffect(() => {
    if(gtgList?.length){
      setList(true);
      setTimeout(() => {
        setList(false);
        setGTGAmount(null);
        setSelectedCampaignGoal('')
      }, 45000);
    }
  },[gtgList])


  const handleSubmit = (event) => {
    console.log("Submitting...",convertGTG(selectedCampaignGoal));
    event.preventDefault();
    event.target.reset();
    setGTGAmount(convertGTG(selectedCampaignGoal));
    //document.getElementById("goal").value = "";
    
    
  };

  const formatNumber = (num) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };

  return (
    <div className="commitment-wrapper">
      {/* <input id="myInput" onChange={handleOnChange}></input>
      <button onClick={handleSubmit}>TEST</button> */}
      <div className="commitment-list-wrapper">
        {!list ? (
          <div className="search">
            <div className="sj-logo">
              <center>
                <img src={stjude} alt="St. Jude" />
              </center>
            </div>
            <div className="search-box">
              <h1>Every dollar counts! </h1>
              <p>
                Enter your 2024 Play Live fundraising goal to see what your
                impact could be for the kids and their families at St. Jude.
              </p>
              <form onSubmit={handleSubmit}>
                <span className="dollar">$</span>
                <input
                  type="text"
                  placeholder="Enter your goal"
                  className="goal"
                  name="goal"
                  id="goal"
                  autoFocus
                  spellCheck="false"
                  autoComplete="off"
                  value={selectedCampaignGoal}
                  onChange={handleOnChange}
                />
                <button type="submit">TEST</button>
              </form>
            </div>
          </div>
        ) : (
          <Fragment>
            <Confetti
              width={1080}
              height={1920}
              numberOfPieces={50}
              gravity={0.05}
              colors={[
                "#8332a7",
                "#1874dc",
                "#64d0e4",
                "#c0da07",
                "#ffc32c",
                "#490e67",
                "#002856",
                "#1eadc1",
                "#75bc21",
                "#ffb125",
                "#c10f3a",
              ]}
            />
            <div className="gtg-list-wrapper">
              {/* {gtgList.map((gifts) => (
                <li key={gifts.id}>
                  <div className={`icon grey ${gifts.icon}`} />
                  <div className="text">
                    ${formatNumber(gifts.amount)} - {gifts.description}
                  </div>
                </li>
              ))} */}
              <div className="gtg-list">
                <div className="beams">
                  {/* left beams */}
                  <div className="beam long" />
                  <div className="beam short" />
                  <div className="beam short" />
                  <div className="beam long" />
                  <div className="beam long" />
                  <div className="beam short" />
                  <div className="beam short" />
                  {/* right beams */}
                  <div className="beam long" />
                  <div className="beam short" />
                  <div className="beam short" />
                  <div className="beam long" />
                  <div className="beam long" />
                  <div className="beam short" />
                  <div className="beam short" />
                </div>
                {/* <div className="stars">
                <div className="star" />
                <div className="star" />
                <div className="star" />
              </div> */}
                <p className="raised_amount">
                  <span>
                    Your <span>${formatNumber(selectedCampaignGoal)}</span> goal
                  </span>
                  <span>could help provide</span>
                </p>

                <ul>
                  {/* <li>
                    <div className="icon handheart"></div>
                    <div className="text"> $100 Shower Chair</div>
                  </li>
                  <li>
                    <div className="icon wagon"></div>
                    <div className="text">$100 Red Wagon</div>
                  </li>
                  <li>
                    <div className="icon paintbrush"></div>
                    <div className="text">$150 Set of school supplies</div>
                  </li>
                  <li>
                    <div className="icon nobill"></div>
                    <div className="text">
                      $150 Grocery stipened for one week
                    </div>
                  </li> */}
                  {gtgList.map((gifts) => (
                    <li key={gifts.id}>
                      <div className={`icon ${gifts.icon}`} />
                      <div className="text">
                        ${formatNumber(gifts.amount)} - {gifts.description}
                      </div>
                    </li>
                  ))}
                </ul>
                <p>for the kids of St. Jude</p>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};

export default Commitment;
