import React, { useState, useEffect, Fragment, useMemo } from "react";
import axios from "axios";
import { Hint } from "react-autocomplete-hint";

import Fundraiser from "../Fundraiser/Fundraiser";

// import BackgroundVideo from "../../img/background.mp4"
import stjude from "../../../../img/sj_logo.png";
import tiltify from "../../../../img/tiltify_logo.png";
import elizabeth from "../../../../img/elizabeth.jpg";
import jacob from "../../../../img/jacob.jpg";
import langston from "../../../../img/langston.jpg";
import raven from "../../../../img/raven.jpg";
import ava from "../../../../img/ava.jpg";
import gabriel from "../../../../img/gabriel.jpg";
import josie from "../../../../img/josie.jpg";
import lennon from "../../../../img/lennon.jpg";
import { useQuery } from "@tanstack/react-query";
import { useGetEveryone, useGetUser } from "../../../../data";
//
// get all the FR ind and team
//

//
const People = (props) => {
  const [calcSpeed, setCalcSpeed] = useState(0);
  const [userSearch, setUserSearch] = useState("");
  const [fundraiser, setFundraiser] = useState(false);
  const [checked, setChecked] = useState(true);

  const [usernameOrTeam, setUserNameOrTeam] = useState("Tiltify username");
  //const [userInfo, setUserInfo] = useState([]);
  const [validation, setValidation] = useState(false);
  //const [peopleList, setPeopleList] = useState([]);
  
  const [username, setUsername] = useState(null);
  const { data: peopleList } = useGetEveryone();
  useEffect(() => {

   
    let spanSelector = document.getElementById("usernames");
    const spanLength = spanSelector.offsetHeight;
    setCalcSpeed(spanLength / 0.8);
  }, [peopleList]);

  const handleTeamChange = () => {
    setChecked(false);
    setUserNameOrTeam("Tiltify team name");
  };
  const handleIndChange = () => {
    setChecked(true);
    setUserNameOrTeam("Tiltify username");
  };

  const { data: userInfo, refetch } = useGetUser(username, !checked);


  const handleSubmit = (event) => {
    event.preventDefault();
    refetch();
    setUsername(encodeURIComponent(userSearch.toLowerCase()));
    

  };

  useEffect(() => {
    console.log("UserInfo",userInfo,(userInfo && userInfo !== "NODATA"));
    //setEnabled(false);
    if (userInfo && userInfo !== "NODATA") {
      setFundraiser(true);
      setValidation(false);
      setTimeout(() => {
        console.log("Resetting page");
        setFundraiser(false);
        setUserSearch("");
        setUsername(null);
      }, 25000);
    } else if (userInfo === "NODATA") {
      setValidation(true);
      setTimeout(() => {
        setValidation(false);
      }, 5000);
    }
  }, [userInfo]);

  return (
    <Fragment>
      {(fundraiser && userInfo) ? (

        <Fundraiser
          username={userInfo.rows.name}
          amount_raised={
            Number(userInfo.rows.amount_raised) + Number(userInfo.rows.offline_amount_raised)
          }
          team={userInfo.rows.team}
          key={Math.random()}
        />

      ) : (
        <div className="kiosk">
          <div id="usernames" className="usernames">
            <div className="usernames__thank_you">
              <img src={stjude} alt="St. Jude Children's Research Hospital" />
              <p>
                {/* Search for your Tiltify username and celebrate your lifetime
              fundraising total for St.&nbsp;Jude! */}
                Celebrate your lifetime fundraising&nbsp;total!
              </p>
            </div>

            <div className="usernames__patients">
              <div className="elizabeth">
                <img src={elizabeth} alt="elizabeth" />
                <span className="usernames__patients__info">
                  St. Jude patient <strong>Elizabeth</strong>
                </span>
              </div>
              <div className="jacob">
                <img src={jacob} alt="jacob" />
                <span className="usernames__patients__info">
                  St. Jude patient <strong>Jacob</strong>
                </span>
              </div>
              <div className="langston">
                <img src={langston} alt="langston" />
                <span className="usernames__patients__info">
                  St. Jude patient <strong>Langston</strong>
                </span>
              </div>
              <div className="raven">
                <img src={gabriel} alt="gabriel" />
                <span className="usernames__patients__info">
                  St. Jude patient <strong>Gabriel</strong>
                </span>
              </div>
              <div className="ava">
                <img src={ava} alt="ava" />
                <span className="usernames__patients__info">
                  St. Jude patient <strong>Ava</strong>
                </span>
              </div>
              <div className="gabriel">
                <img src={lennon} alt="lennon" />
                <span className="usernames__patients__info">
                  St. Jude patient <strong>Lennon</strong>
                </span>
              </div>
              <div className="josie">
                <img src={josie} alt="raven" />
                <span className="usernames__patients__info">
                  St. Jude patient <strong>Josie</strong>
                </span>
              </div>
              <div className="lennon">
                <img src={raven} alt="raven" />
                <span className="usernames__patients__info">
                  St. Jude patient <strong>Raven</strong>
                </span>
              </div>
            </div>
            <div
              className="usernames__names"
              style={{ animationDuration: `${calcSpeed}s` }}
            >
              <div>
                <ul>
                  {peopleList?.map(function (d, idx) {
                    return <li key={idx}>{d.label}&nbsp;&nbsp;&nbsp;</li>;
                  })}
                </ul>
              </div>
            </div>
            <div className="usernames__search_button">
              {/* <span className="cta-primary">SEARCH FOR YOUR FUNDRAISING AMOUNT</span> */}
              <form onSubmit={handleSubmit}>
                <Hint allowTabFill={true} options={peopleList}>
                  <input
                    type="text"
                    value={userSearch}
                    // placeholder="Tiltify username"
                    placeholder={usernameOrTeam}
                    autoComplete="off"
                    className="search_field"
                    name="name"
                    autoFocus
                    spellCheck="false"
                    onChange={(event) => setUserSearch(event.target.value)}
                  />
                </Hint>
                {validation ? (
                  <p className="validation">
                    Please enter a valid Tiltify username
                  </p>
                ) : (
                  <p></p>
                )}
                <button type="submit">Search</button>
              </form>
              <div className="usernames__select">
                <Checkbox
                  type="radio"
                  id="individual"
                  name="indorteam"
                  value="Individual"
                  defaultChecked="defaultChecked"
                  className={checked && "active"}
                  onChange={handleIndChange}
                />
                <Checkbox
                  type="radio"
                  id="team"
                  name="indorteam"
                  value="Team"
                  className={!checked && "active"}
                  onChange={handleTeamChange}
                />
              </div>
              <img src={tiltify} alt="Tiltify" className="tiltify" />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

const Checkbox = ({
  id,
  type,
  name,
  value,
  defaultChecked,
  className,
  onChange,
}) => {
  return (
    <div>
      {/* <input name={name} type="radio" value={value} />
      <label htmlFor={value}>{label}</label> */}

      <input
        type={type}
        id={id}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
      // onChange={onChange}
      />
      <label className={className} onClick={onChange} htmlFor={id}>
        {value}
      </label>
    </div>
  );
};


export default People;



/* 

async function getPeopleFromCache() {
  //@type {IDBDatabase} 
  const db = await (new Promise((resolve) => {
    const req = window.indexedDB.open("offline_cache", 1);
    req.onsuccess = (event) => { resolve(event.target.result) };
    req.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore("everyone", { keyPath: "username_lower" });
      resolve(db);
    }
  }));
  const tx = db.transaction(["everyone"], "readonly");
  const store = tx.objectStore("everyone");

  return await (new Promise((resolve) => {
    const peopleList = [];
    store.openCursor().onsuccess = (event) => {
      const cursor = event.target.result;
      if (cursor) {
        peopleList.push(cursor.value);
        cursor.continue();
      } else {
        resolve(peopleList);
      }
    }
  }))

}

async function savePeopleToCache(people) {
  //@type {IDBDatabase} 
  const db = await (new Promise((resolve) => {
    const req = window.indexedDB.open("offline_cache", 1);
    req.onsuccess = (event) => { resolve(event.target.result) };
    req.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore("everyone", { keyPath: "label" });
      resolve(db);
    }
  }));
  const tx = db.transaction(["everyone"], "readwrite");
  const store = tx.objectStore("everyone");
  people.forEach((person) => {
    store.put(person);
  });
} */