import { useQuery } from "@tanstack/react-query";


export function useGetEveryone() {
    
    return useQuery({
        queryKey: ["getEveryone"],
        queryFn: ()=>fetch(new URL("everyone", process.env.REACT_APP_SERVICE).toString()).then(response => response.json()).then(data => data.map((response) => ({label: response.name}))),
        placeholderData:[],
      })
}

export function useGetUser(username,isTeam,enabled=true) {
    return useQuery({
        queryKey: ['getUser', username, isTeam,enabled],
        queryFn: enabled? async () => {
          const res = await fetch(new URL(`user/${username}/${isTeam}`, process.env.REACT_APP_SERVICE))
          return await res.json();
        } : null,
        enabled:enabled
      })
}

export function useGetClosest(total,enabled) {
    return useQuery({
        queryKey: ['getClosest', total],
        queryFn: async () => {
            const res = await fetch(new URL(`closest/${total}`, process.env.REACT_APP_SERVICE))
            return await res.json();
        },
        enabled
    })
}

export function useGTGMilestones(goal) {
    return useQuery({
        queryKey: ['getGTGMilestones', goal],
        queryFn: async () => {
            if(goal===null) return [];
            const res = await fetch(new URL(`gtg-milestones/${goal}`, process.env.REACT_APP_SERVICE))
            return (await res.json()).map((gtg) => {
                return {
                  id: gtg.id,
                  amount: Number(gtg.value_rounded),
                  provide: gtg.provide_cover,
                  icon: gtg.icon,
                  description: gtg.description,
                };
              });
        }
    })
}