import React, { useState, useEffect, useRef, Fragment } from "react";

import axios from "axios";
import { Hint } from "react-autocomplete-hint";

import Fundraiser from "./Fundraiser";

// import video from "../../../img/b-roll-2.mp4";

import campus from "../../../img/campus_med.jpg";
import stjude from "../../../img/sj_logo.png";
import {
  doctorIcon,
  researcherIcon,
  nurseIcon,
  kidIcon,
  chemoIcon,
  familyIcon,
  hospitalIcon,
  treatmentIcon,
  signIcon,
  foodIcon,
  nobillIcon,
  travelIcon,
  bandaidIcon,
  heartIcon,
  handheartIcon,
  redwagonIcon,
  paintbrushIcon,
  teamIcon,
  childrenIcon,
  wagonIcon,
} from "../../../img/icons";
import { useGetEveryone,useGetUser } from "../../../data";

const Main = () => {
  //USESTATE
  const [userSearch, setUserSearch] = useState("");
  const [usernameSearch, setUsernameSearch] = useState(null);;
  const [validation, setValidation] = useState(false);
  const [checked, setChecked] = useState(true);
  const [username, setUsername] = useState("");
  const [userAmount, setUserAmount] = useState(0);
  const [gtg, setGtg] = useState("");
  const [gtgPL, setGtgPL] = useState("");
  const [gtgIcon, setGtgIcon] = useState("");
  const [gtgAmount, setGtgAmount] = useState(0);
  const [gtgProvideCover, setGtgProvideCover] = useState("");
  const [fundraiser, setFundraiser] = useState(false);
  
  //const [peopleList, setPeopleList] = useState([]);
  const [usernameOrTeam, setUserNameOrTeam] = useState("Tiltify username");

  const ref = useRef(null);
  const { data: peopleList } = useGetEveryone();
  const { data: userData, isPending } = useGetUser(usernameSearch, !checked)
  
  useEffect(() => {
    if (userData && !isPending) {
      
      if (userData!== "NODATA") {
        setUsername(userData.rows.name);
        setUserAmount(
          Number(userData.rows.amount_raised) +
            Number(userData.rows.offline_amount_raised)
        );
        setGtgAmount(
          Math.trunc(
            (Number(userData.rows.amount_raised) +
              Number(userData.rows.offline_amount_raised)) /
              (Number(userData.data?.value)??1)
          )
        );
        setGtg(userData.data?.description);
        setGtgPL(userData.data?.description_pl);
        setGtgIcon(userData.data?.icon);
        setGtgProvideCover(userData.data?.provide_cover);

        setValidation(false);
        setFundraiser(true);
        setTimeout(() => {
          
          setFundraiser(false);
          setUserSearch("");
          setChecked(true);
          setUsernameSearch(null);
        }, 45000);
        
      }
      if (userData === "NODATA") {
        setValidation(true);
        setTimeout(() => {
          setValidation(false);
        }, 5000);
        
      }

    }
  }, [userData]);
  // USEEFFECT

  useEffect(() => {
    if (!fundraiser) {
      setTimeout(() => {
        const div = ref.current; // corresponding DOM node
        div.className = "ribbon active";
      }, 500);
    }
  }, [fundraiser]);

  useEffect(() => {
    if (username.length > 0) {
      setFundraiser(true);
      setValidation(false);
      setTimeout(() => {
        setFundraiser(false);
        setUserSearch("");
        setUserNameOrTeam("Tiltify username");
        setUsernameSearch(null);
      }, 45000);
    }
  }, [username]);

  // FUNCTIONS
  const handleSubmit = (event) => {
    console.log("this ran");
    event.preventDefault();
    event.target.reset();
    setUsernameSearch(encodeURIComponent(userSearch.toLowerCase()));
  };

  // console.log(userInfo);

  const handleTeamChange = () => {
    setChecked(false);
    setUserNameOrTeam("Tiltify team name");
  };
  const handleIndChange = () => {
    setChecked(true);
    setUserNameOrTeam("Tiltify username");
  };

  // COMPONENTS
  const Checkbox = ({
    id,
    type,
    name,
    value,
    defaultChecked,
    className,
    onChange,
  }) => {
    return (
      <div>
        {/* <input name={name} type="radio" value={value} />
        <label htmlFor={value}>{label}</label> */}

        <input
          type={type}
          id={id}
          name={name}
          value={value}
          defaultChecked={defaultChecked}
        />
        <label className={className} onClick={onChange} htmlFor={id}>
          {value}
        </label>
      </div>
    );
  };
  return (
    <Fragment>
      <div className="gtg-wrapper">
        <div className="main">
          <div className={!fundraiser ? "video-wrapper" : "video-wrapper up"}>
            <video playsInline autoPlay muted loop poster={campus} id="bgvid">
              <source
                src="https://plk.dev.experience.stjude.org/b-roll-2.mp4"
                // src={video}
                type="video/mp4"
              />
            </video>
          </div>
          <div className="viewport">
            {/* <div className="content-wrapper">
              <div className="bar top"></div>
              <div className="main-content">
                <p>
                  <em>Celebrate your lifetime impact</em>
                </p>
                <p>
                  <em>for St. Jude</em>
                </p>
                <img src={stjude} />
              </div>
              <div className="bar bottom"></div>
            </div> */}
            <div className="content-wrapper2">
              {!fundraiser ? (
                <div className="ribbon" ref={ref}>
                  <div className="sj-logo fadeIn">
                    <img src={stjude} alt="St. Jude" />
                  </div>

                  <div className="ribbon-1">
                    <span className="inner">
                      <span className="fadeLeft">Celebrate Your</span>
                    </span>
                  </div>

                  <div className="ribbon-2">
                    <span className="inner">
                      <span className="fadeRight">Lifetime Impact</span>
                    </span>
                  </div>

                  <div className="ribbon-3">
                    <span className="inner">
                      <span className="fadeLeft">on&nbsp;&nbsp;St. Jude</span>
                    </span>
                  </div>

                  <div className="search_button fadeIn">
                    <form onSubmit={handleSubmit}>
                      <Hint allowTabFill={true} options={peopleList}>
                        <input
                          type="text"
                          placeholder={usernameOrTeam}
                          autoComplete="off"
                          className="search_field"
                          name="name"
                          autoFocus
                          spellCheck="false"
                          onChange={(event) =>
                            setUserSearch(event.target.value)
                          }
                        />
                      </Hint>
                      {validation ? (
                        <p className="validation">
                          Please enter a valid Tiltify username
                        </p>
                      ) : (
                        <p></p>
                      )}
                      <button type="submit">Search</button>
                    </form>
                    <div className="select_buttons">
                      <Checkbox
                        type="radio"
                        id="individual"
                        name="indorteam"
                        value="Individual"
                        defaultChecked="defaultChecked"
                        className={checked ? "active" : ""}
                        onChange={handleIndChange}
                      />
                      <Checkbox
                        type="radio"
                        id="team"
                        name="indorteam"
                        value="Team"
                        className={!checked ? "active" : ""}
                        onChange={handleTeamChange}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <Fundraiser
                  username={username}
                  amount_raised={userAmount}
                  gtg={gtgAmount > 1 ? gtgPL : gtg}
                  gtgAmount={gtgAmount > 1 ? gtgAmount : ""}
                  gtgProvideCover={gtgProvideCover}
                  icon={
                    gtgIcon === "doctor"
                      ? doctorIcon
                      : gtgIcon === "researcher"
                      ? researcherIcon
                      : gtgIcon === "nurse"
                      ? nurseIcon
                      : gtgIcon === "kid"
                      ? kidIcon
                      : gtgIcon === "chemo"
                      ? chemoIcon
                      : gtgIcon === "family"
                      ? familyIcon
                      : gtgIcon === "hospital"
                      ? hospitalIcon
                      : gtgIcon === "treatment"
                      ? treatmentIcon
                      : gtgIcon === "sign"
                      ? signIcon
                      : gtgIcon === "food"
                      ? foodIcon
                      : gtgIcon === "nobill"
                      ? nobillIcon
                      : gtgIcon === "travel"
                      ? travelIcon
                      : gtgIcon === "bandaid"
                      ? bandaidIcon
                      : gtgIcon === "heart"
                      ? heartIcon
                      : gtgIcon === "handheart"
                      ? handheartIcon
                      : gtgIcon === "redwagon"
                      ? redwagonIcon
                      : gtgIcon === "paintbrush"
                      ? paintbrushIcon
                      : gtgIcon === "children"
                      ? childrenIcon
                      : gtgIcon === "team"
                      ? teamIcon
                      : gtgIcon === "wagon"
                      ? wagonIcon
                      : ""
                  }
                />
              )}
            </div>
            <p className="legal">
              When you make a donation using this information, your donation
              will be used to provide breakthrough research, treatment and
              cures. Items listed here are representative of services and
              supplies that are part of the treatment and care of children at
              St. Jude. The cost of each item or service is an approximation,
              and will vary based on actual costs incurred and individual
              patient needs. Your donation will be used for the general
              operating needs of St. Jude, where no family ever receives a bill
              for treatment, travel, housing or food.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Main;
