import React, { useState } from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import { useGetClosest } from "../data";
function Closest() {
  const [total, setTotal] = useState("");
  const [data, setData] = useState(false);
  //const [closestFR, setClosestFR] = useState("");
  const { data: closestFR ,refetch} = useGetClosest(total,false)
  
  const handleSubmit = (event) => {
    event.preventDefault();
    //setData(false);
    refetch();
    // alert(`The total $$ you entered was: ${total}`);
    /* axios
      .post(process.env.REACT_APP_SERVICE + "/getClosestTo/", {
        total: total,
      })
      .then((response) => {
        setClosestFR(response.data);

        // if (closestFR.data.length > 0) {
        setData(true);
        // }
      })
      .catch((err) => {
        console.log("ERROR", err);
      }); */
    
  };
  //   console.log("All the data?", closestFR, data);
  return (
    <form onSubmit={handleSubmit}>
      <label>
        Enter the closest to $:
        <input
          type="number"
          value={total}
          onChange={(e) => setTotal(e.target.value)}
        />
      </label>
      <button type="submit"> search</button>
      <header>*** Results ***</header>
      <div>
        {closestFR && (
          <ul>
            {closestFR.map((closestFR, index) => (
              <li key={index}>
                <span>{closestFR.name}</span>{" "}
                <span>
                  TOTAL:{" "}
                  {closestFR.total
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </span>
              </li>
            ))}
          </ul>
        )}
      </div>
    </form>
  );
}
/* 
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Closest />); */
export default Closest;
