
const useGtG = (_goal) => {

  const goal = Number(_goal);
  if (goal < 100) {
    return (0);
  } else if (goal < 500) {
    return (100);
  } else if (goal < 1000) {
    return (500);
  } else if (goal < 1500) {
    return (1000);
  } else if (goal < 2000) {
    return (1500);
  } else if (goal < 3000) {
    return (2000);
  } else if (goal < 5000) {
    return (3000);
  } else if (goal < 7500) {
    return (5000);
  } else if (goal < 10000) {
    return (7500);
  } else if (goal < 20000) {
    return (10000);
  } else if (goal < 30000) {
    return (20000);
  } else if (goal < 40000) {
    return (30000);
  } else if (goal < 50000) {
    return (40000);
  } else if (goal < 60000) {
    return (50000);
  } else if (goal < 70000) {
    return (60000);
  } else if (goal < 75000) {
    return (70000);
  } else if (goal < 80000) {
    return (75000);
  } else if (goal < 90000) {
    return (80000);
  } else if (goal < 100000) {
    return (90000);
  } else {
    return (100000);
  }




};

export default useGtG;
